<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1 v-if="$myCity" v-html="'Реклама '+ $myCity.name"></h1>
                <h1 v-else>Заказать рекламу</h1>
            </div>
            <div class="default_box_content bg_white p15" v-if="Preloader">
                <form @submit.prevent="send">
                    <div class="advertising_user_title text-left" v-if="item">
                        <div v-if="item.title" class="pb-3"><b>{{item.title}}</b></div>
                        <div v-if="item.text" v-html="$htmContext(item.text)"></div>
                        <div v-if="item.custom_fields && item.custom_fields.length" class="services">
                            <label><b>Услуги</b></label>
                            <div class="services_placeholder_box">
                                <small>Выберите интересующую услугу</small><small>убрать / добавить<input type="checkbox" class="switch" :checked="check_placeholder"></small>
                            </div>
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="(it, index) in item.custom_fields" :key="index" class="tr_table">
                                        <div class="table_main_box">
                                            <div class="table_box">
                                                
                                                <div v-html="it.name" class="text-left">
                                                </div>
                                                <div v-html="it.price" class="text_box text-right">
                                                </div>
                                                <div class="checkbox_box">
                                                    <input type="checkbox" class="switch" @change="add_service(index , it.name + ' - ' + it.price, $event.srcElement.checked)">
                                                </div>
                                            </div><!-- :value="(index+1) +' - '+ it.name+':'+it.price" v-model="form.service" --><!-- @change="add_service(it.name, it.price, $event.srcElement.checked)" -->
                                            <div class="edit_box" v-if="it.description">
                                                <div class="open_desc" v-b-toggle="'collapse_'+ index">
                                                    Подробнее
                                                </div>
                                            </div>
                                            <b-collapse :id="'collapse_'+index" accordion="my-accordion" class="mt-2" v-if="it.description">
                                                <div v-html="$htmContext(it.description)"></div>
                                            </b-collapse>
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <YouTube v-if="item && item.youtube" :item="item.youtube"></YouTube>
                    <VkFrame v-if="item && item.vk_video && item.vk_video.url" :item="item.vk_video"></VkFrame>
                    <div v-for="(form_item, index) in data" :key="index">
                        <label :for="form_item.name" class="main_input_label" v-html="form_item.label"></label>
                        <input v-if="form_item.name !== 'telephone'" :id="form_item.name" type="text" class="main_input" v-model="form[form_item.name]" required>
                        <input v-else :id="form_item.name" class="main_input" type="tel" v-model="form[form_item.name]" required />
                        <!-- <input v-else :id="form_item.name" class="main_input" type="tel" v-mask="'+7(###)###-####'" v-model="form[form_item.name]" required /> -->
                    </div>
                    <button class="button button_default">Отправить</button>
                </form>
            </div>
            <div v-else>
                <PreLoadBox />
            </div>
        </div>
    </div>
</template>
<script>
import VkFrame from '@/components/Plagins/VkFrame/index';
export default {
    name: "Advertising",
    components: {
        VkFrame
    },
    metaInfo() {
        return {
            title: `Реклама  - ${this.$myCity.name}`,
            meta: [
                {name: 'description', itemprop: 'description', content: `Реклама в городе ${this.$myCity.name} на информационном портале Окей Город.` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Реклама  - ${this.$myCity.name}`},
                {property: 'og:description', content: `Реклама в городе ${this.$myCity.name} на информационном портале Окей Город.`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            Preloader: null,
            check_placeholder: true,
            item: null,
            data: [{
                    name: 'name',
                    label: 'Ваше имя'
                },
                {
                    name: 'telephone',
                    label: 'Телефон'
                },
                {
                    name: 'email',
                    label: 'E-mail'
                },
            ],
            form: {
                name: null,
                telephone: null,
                email: null,
                service: {}
            },
        }
    },
    methods: {
        api() {
            let url = this.$config.api_url + this.$route.params.city + '/Advertising.get';
            this.$http.get(url).then((response) => {
                this.item = response.data.response;
            }).finally(() => {
                this.Preloader = 'finish';
            })
        },
        send() {
            this.Preloader = null;
            let url = this.$config.api_url + this.$route.params.city + '/Advertising.form';
            this.$http.post(url, this.form, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(() => {
                this.$notify({
                    group: 'error',
                    type: 'success',
                    text: 'Ваше обращение успешно отправлено',
                    title: 'Поздравляем!',
                    duration: 10000,
                });
                this.formReset();
            }).finally(() => {
                this.Preloader = 'finish';
            });
        },
        formReset() {
            this.form.name = null;
            this.form.telephone = null;
            this.form.email = null;
            this.form.service = {};
        },
        add_service(name, price, check) {
            if(check) {
                this.form.service[name] = price;
                //this.form.service = this.form.service;
            } else {               
                delete this.form.service[name];
                //this.form.service = this.form.service;
            }
        }

    },
    mounted() {
        this.$scrollToTop();
        setInterval(() => {
            this.check_placeholder = !this.check_placeholder
        }
        , 1500);
        this.api();
    }

}
</script>
<style scoped>
.advertising_user_title {
    margin-bottom: 20px;
}

.button {
    margin-bottom: 20px;
}

.table_main_box {
    padding: 10px 0;
}

.table_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.table_box div {
    margin: 0;
}

tr {
    outline: none;
}

.open_desc {
    color: #00b4e1;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0 !important;
    outline: none;
}

.edit_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
}

.services {
    padding: 20px 0 0;
}
.text_box {
    width: 100%;
    padding-right: 10px;
}

.services_placeholder_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.services_placeholder_box input {
    margin-left: 10px;
}
</style>